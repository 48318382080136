<script>
export default {
  props: {
    floors: {},
    current: {},
  },
  data() {
    return {
      clone_floor_select: null,
    };
  },
  methods: {
    CopyFloor() {
      this.$emit("copy");
    },
    clearSelected() {
      this.clone_floor_select = null;
    },
  },
  watch: {
    clone_floor_select(v) {
      console.log(v);
      this.$emit("select-floor", v);
    },
  },
};
</script>
<template>
  <div>
    <b-modal
      id="modal-select-floor"
      ref="modal"
      class="py-4"
      :title="$t('objects.create.edit_block')"
      size="xl"
      hide-footer
      hide-header-close
    >
      <label>
        {{ $t("objects.create.choose_clone") }}
      </label>
      <select v-model="clone_floor_select" class="form-control">
        <option
          v-for="(floor, index_clone) in floors"
          :key="index_clone"
          :disabled="index_clone === current"
          :value="index_clone"
        >
          {{ index_clone + 1 }} -
          {{ $t("objects.create.floor") }}
        </option>
      </select>
      <button type="button" class="btn btn-primary" @click="CopyFloor">
        <i class="fa fa-copy" />
        {{ $t("objects.create.clone") }}
      </button>
    </b-modal>
  </div>
</template>
