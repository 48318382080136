import { ref } from 'vue'
import { v3ServiceApi } from "@/services/v3/v3.service";

let prom = null;
const currency = ref()
export function useCurrency() {

  async function fetchCurrency() {
    if (prom) return prom;
    prom = v3ServiceApi.currency
      .getCurrent()
      .then((res) => (currency.value = res.data))
    return prom
  }

  return {
    fetchCurrency,
    currency,
  }
}
