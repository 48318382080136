<script>
import { XFormInput } from "@/components/ui-components/form-input";
import { XModalCenter } from "@/components/ui-components/modal-center";
import { v3ServiceApi } from "@/services/v3/v3.service";
import { XFormSelect } from "@/components/ui-components/form-select";

export default {
  name: "OrderCategoryModal",
  components: {
    XFormInput,
    XModalCenter,
    XFormSelect,
  },
  props: {},
  emits: ["option-selected", "close-modal"],
  data() {
    return {
      options: [],
      current: null,
    };
  },
  async created() {
    const response = await v3ServiceApi.orderCategory.index();
    this.options = response.data.data;
  },
  methods: {
    closeCreatingModal() {
      this.$emit("close-modal");
    },

    async save() {
      const isSatisfied = await this.$refs["creating-observer"].validate();
      if (isSatisfied) {
        this.$emit("option-selected", this.current);
      }
    },
  },
  computed: {
    selectOptions() {
      return this.options.map((el) => ({
        value: el.id,
        text: el.name[this.$i18n.locale],
      }));
    },
  },
};
</script>

<template>
  <x-modal-center
    :bilingual="true"
    apply-button-text="save"
    cancel-button-text="cancel"
    footer-class="d-flex justify-content-between x-gap-1"
    apply-button-class="w-100"
    cancel-button-class="w-100"
    :apply-button-loading="applyButtonLoading"
    :modal-container-style="{
      'max-width': '480px',
      'max-height': '720px',
      width: '50%',
      overflowY: 'scroll',
    }"
    @close="closeCreatingModal"
    @cancel="closeCreatingModal"
    @apply="save"
  >
    <template #header>
      <h3 class="x-font-size-36px font-craftworksans color-gray-600">
        Выберите Категорию
      </h3>
    </template>

    <template #body>
      <validation-observer
        ref="creating-observer"
        class="client-type-creating-body"
      >
        <!--  ? STATUS TITLE UZ     -->
        <validation-provider
          ref="clientTypeNameVProvider"
          v-slot="{ errors }"
          name="name-ru-provider"
          rules="required"
          class="title-uz-provider"
        >
          <x-form-select
            v-model="current"
            :error="!!errors[0]"
            :options="selectOptions"
            :placeholder="$t('Выберите Категорию')"
            value-field="value"
          />
        </validation-provider>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </validation-observer>
    </template>
  </x-modal-center>
</template>

<style lang="scss" scoped>
.client-type-creating-body {
  margin-top: 1rem;
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  font-family: Inter, sans-serif;
  color: var(--gray-600);
  //grid-template-areas:
  //  "titleUz titleRu"
  //  "statusType statusType";
  //
  //.title-uz-provider {
  //  grid-area: titleUz;
  //}
  //
  //.title-ru-provider {
  //  grid-area: titleRu;
  //}
  //
  //.status-type-provider {
  //  grid-area: statusType;
  //}
}

.icons-collection-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
}

.status-pick-color-title {
  font-size: 18px;
  color: var(--gray-400);
  font-weight: 500;
  font-family: Inter, serif;
}

.error__provider {
  color: red;
  font-size: 12px;
}
</style>
